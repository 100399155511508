class BackButton extends HTMLElement {
  constructor() {
    super();
    this.handleClick = this.handleClick.bind(this);
  }

  connectedCallback() {
    this.fallbackUrl = this.dataset.fallbackUrl || '/';
    this.buttonText = this.dataset.buttonText || 'Back to shop';
    this.render();
    this.addEventListener('click', this.handleClick);
  }

  disconnectedCallback() {
    this.removeEventListener('click', this.handleClick);
  }

  handleClick(event) {
    event.preventDefault();

    // Check if there's no history or if it's just the current page
    if (history.length <= 1) {
      window.location.href = this.fallbackUrl;
      return;
    }
    // Use fallback URL if there's no referrer or if it's from a different domain
    try {
      if (document.referrer) {
        const referrerUrl = new URL(document.referrer);
        const currentDomain = window.location.hostname;

        if (referrerUrl.pathname === '/' || referrerUrl.hostname !== currentDomain) {
          window.location.href = this.fallbackUrl;
        } else {
          window.history.back();
        }
      } else {
        window.location.href = this.fallbackUrl;
      }
    } catch (e) {
      // Fallback URL if there's any issue with URL parsing
      window.location.href = this.fallbackUrl;
    }
  }

  render() {
    this.innerHTML = `
      <a href="${this.fallbackUrl}" class="button--link-icon">
        <span>&#9664;</span>
        <slot>${this.buttonText}</slot>
      </a>
    `;
  }
}

customElements.define('back-button', BackButton);
